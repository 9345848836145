import { createStore } from "vuex";
import { common } from '@/store/common'
import { module1 } from '@/store/module1'
import { prospects } from '~/store/prospects'
import { engineering } from '@/store/engineering'
import { economics } from '@/store/economics'

const store = createStore({
	modules: {
		common: common,
		module1: module1,
		prospects: prospects,
		engineering: engineering,
		economics: economics
	}
});

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(store)
})