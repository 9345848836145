export const prospects = {
    namespaced: true,
    state: () => ({
        options: {
            caseData: {},
            caseTypes: [
                {
                    id: null,
                    name: '',
                    isActive: false
                },
                {
                    id: 'oil',
                    name: 'prospects.type.oil',
                    isActive: false
                },
                {
                    id: 'gas',
                    name: 'prospects.type.gas',
                    isActive: false
                },
                {
                    id: 'mixed',
                    name: 'prospects.type.mixed',
                    isActive: false
                }
            ],
            resultIterationsCount: [
                {
                    name: '1000', id: 1000, isActive: false
                },
                {
                    name: '2000', id: 2000, isActive: false
                },
                {
                    name: '5000', id: 5000, isActive: true
                }
            ],
            tabs: [
                {
                    id: 'main',
                    name: 'units.volume',
                    isActive: false
                },
                {
                    id: 'table',
                    name: 'units.unit',
                    isActive: false
                }
            ],
            resultRelevance: false,
        },
        caseUnits: null,
        allUnits: null,
        sharedChance: null,
        result: {
            data: null,
            id: null
        }
    }),
    getters: {
        allCaseData: (state) => {
            return {
                result_quantity: state.options.resultIterationsCount.find(tab => tab.isActive).id,
                data_layers: state.caseUnits,
                shared_chance: state.sharedChance
            }
        }
    },
    mutations: {
        setCaseData(state, payload) {
            payload.caseId ? state.options.caseData.caseId = payload.caseId : false
            payload.objectId ? state.options.caseData.objectId = payload.objectId : false
            payload.projectId ? state.options.caseData.projectId = payload.projectId : false
        },
        setCaseType(state, payload) {
            state.options.caseTypes.map(item => item.id === payload ? item.isActive = true : item.isActive = false)
        },
        setActiveCaseTab(state, payload) {
            const tabId = payload

            state.options.tabs.forEach(tab => {
                tab.isActive = tab.id === tabId ? true : false
            })
        },
        updateResultRelevance(state, payload) {
            state.options.resultRelevance = payload
        },
        updateResultIterationsCount(state, payload) {
            const count = payload

            state.options.resultIterationsCount.forEach(element => {
                if (Number(element.id) === Number(count)) {
                    element.isActive = true
                } else {
                    element.isActive = false
                }
            })
        },
        saveResult(state, payload) {
            const { result } = payload
            state.result.data = result
        },
        addCaseUnit(state, payload) {
            const { id, data } = payload

            if (!state.caseUnits) {
                state.caseUnits = {}
            }

            state.caseUnits[id] = data
        },
        removeCaseUnit(state, payload) {
            delete state.caseUnits[payload]

            if (!Object.keys(state.caseUnits).length) {
                state.caseUnits = null
                state.result.data = null
            }
        },
        removeAllUnits(state) {
            state.caseUnits = null
            state.result.data = null
        },
        resetCaseResult(state) {
            state.result = {
                data: null,
                id: null
            }

            this.commit('prospects/updateResultRelevance', false)
        }
    },
    actions: {
        async getCase({ state }, payload) {
            const { data, error } = await useFetch(`/api/projects/${state.options.caseData.projectId}/objects/${state.options.caseData.objectId}/cases/${state.options.caseData.caseId}`)

            if (error.value) return {
                error: true,
                errorMessage: error.value.data.message
            }

            const caseData = data.value

            this.commit('prospects/setCaseType', caseData.type)
            this.commit('common/setObjectOptions', { name: data.value.object.name, id: data.value.object.id })
            this.commit('common/setObjectCaseOptions', { name: data.value.name, id: data.value.id })
            this.commit('prospects/updateResultIterationsCount', caseData.result_quantity || 1000)

            state.caseUnits = caseData.data_layers   
            state.sharedChance =  caseData.shared_chance
            
            await this.dispatch('prospects/getAllUnits')

            if (caseData.actual_job) {
                this.commit('prospects/saveResult', {
                    result: caseData.actual_job.result
                })

                this.commit('prospects/updateResultRelevance', true)
            }

            this.commit('prospects/setActiveCaseTab', 'main')
            this.commit('common/setLoadingStatus', false)
        },
        async getAllUnits({ state }, payload) {
            const { data, error } = await useFetch(`/api/projects/${state.options.caseData.projectId}/layers`)

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })
                this.commit('common/setLoadingStatus', false)

                useNuxtApp().$geologyProspectCaseError(errorMessage.message, new Error())
                
                return
            }

            state.allUnits = data.value
        },
        async getResult({ state, getters }) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/objects/${state.options.caseData.objectId}/cases/${state.options.caseData.caseId}/calc_init`, {
                method: 'POST',
                body: JSON.stringify(getters.allCaseData)
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })
                this.commit('common/setLoadingStatus', false)

                useNuxtApp().$geologyProspectCaseError(errorMessage.message, new Error())
                
                return
            }

            useGtag().gtag('event', 'start_calculation', {
				'case_type': 'geology_prospects' 
			})

            if (typeof ym !== 'undefined') {
                ym(useRuntimeConfig().public.YM_ID,'reachGoal','start_calculation')
            }

            try {
                state.result.id = data.value.job.id

                if (data.value.job.result && data.value.job.status === 2) {
                    if (Object.keys(data.value.job.result).length) {
                        this.commit('prospects/saveResult', {
                            result: data.value.job.result
                        })
                        this.commit('prospects/updateResultRelevance', true)
                    } else {
                        this.commit('common/setErrorData', {
                            statusCode: 500,
                            message: 'No result',
                            showError: true
                        })
                    }
                }
            } catch (e) {
                useNuxtApp().$geologyProspectCaseError('Error getting result', e)
            }
            
        },
    }
}